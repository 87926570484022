<template>
  <v-app >
      <v-main class="blue lighten-4">
          <v-container fill-height fluid>
            <v-row 
                align="center"
                justify="center"
                style="max-width: 600px"
                class="mx-auto"
                >
                <v-col v-if="enviado">
                    <v-card>
                        <div class="blue-grey text-center py-4 white--text">
                            <v-img class="mx-auto" max-width="100" src="@/assets/logo.png"></v-img> 
                            <div class="text-h6">CLINICA GOOD HOPE</div>
                        </div>
                        <v-card-text class="text-center text-h5">
                            Su solicitud de restablecer la contraseña ha sido enviado a <b>{{mail}}</b>, 
                            si no lo encuentra quizás esté en la sección de "no deseados" o "span" 

                        </v-card-text>
                        <v-card-actions class="text-center">
                           <v-btn class="mx-auto" color="primary"  to="/login">Volver al inicio</v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-col>
                <v-col v-else>
                    <div style="height: 24px;" class="d-block d-md-none"></div>
                    <v-card elevation="15" >
                        <div class="blue-grey text-center py-4 white--text">
                            <v-img class="mx-auto" max-width="100" src="@/assets/logo.png"></v-img> 
                            <div class="text-h6">CLINICA GOOD HOPE</div>
                        </div>
                        <div v-if="tramite">
                            <v-progress-linear :indeterminate="true"></v-progress-linear>
                        </div>
                        <v-card-text class="text-center">
                           
                            <div class="text-h4">Recuperar contraseña</div>
                            
                        </v-card-text>
                        <v-card-text>
                            ¿Olvidaste tu contraseña?, no te preocupes, ingresa tu correo electrónico
                            con el cual te has registrado. Te enviaremos un correo dándote indicaciones
                            para continuar con el proceso.
                        </v-card-text>
                        <v-card-text>
                            <v-text-field
                                name="email"
                                label="Correo electrónico"
                                id="id"
                                outlined
                                focusable
                                prepend-inner-icon="mdi-email-outline"
                                append-icon="mdi-send"
                                @keypress.enter="enviarCorreo()"
                                v-model="mail"
                                :rules="[email]"
                                :disabled="tramite"
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions class="text-center">
                            <v-row>
                                <v-col cols="12" md="6"><v-btn outlined to="/login">Volver al inicio</v-btn></v-col>
                                <v-col cols="12" md="6"><v-btn :disabled="tramite || !validarEmail" color="primary" @click="enviarCorreo()">
                                    Recuperar contraseña
                                </v-btn></v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
          </v-container>
      </v-main>
  </v-app>
</template>

<script>

import securityService from '@/service/hopeSeguridad'
export default {
    data(){return{
        enviado:false,
        tramite: false,
        mail:'',
        email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'E-mail no válido.'
        },
    }},
    methods:{
        enviarCorreo(){
            this.tramite = true;
            if(this.validarEmail){
                securityService.recuperarContrasena(this.mail)
                .then( response => {
                    this.tramite = false;
                    if(response.data.codigo === "OK"){
                        this.enviado = true;
                    }else{
                        alert("Correo no registrado")
                        this.enviado = false;
                    }
                    
                })
                .catch(()=>{
                    alert("Tenemos un problema con nuestros servicios, intente de nuevo mas tarde");
                    this.tramite = false;
                    this.enviado = false;
                })
            }
        },
    },
    computed:{
        validarEmail: function() {
            return  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.mail)
            
        }
    }
}
</script>

<style>

</style>